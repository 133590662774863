<template>
	<div class="f82m4" v-if="data != null">
		<div class="viral-news-breadcumb-area section-padding-50">
			<div class="container h-100">
				<div class="row h-100 align-items-center">
					<!-- Breadcumb Area -->
					<div class="col-12">
						<h3>{{ data.name }}</h3>
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb">
								<li class="breadcrumb-item"><a href="/">Home</a></li>
								<li class="breadcrumb-item active" aria-current="page">
									{{ data.name }}
								</li>
							</ol>
						</nav>
					</div>
				</div>
			</div>
		</div>

		<div id="main-info" class="section-padding-50">
			<div class="container">
				<div class="row">
					<div class="col-12 col-md-6">
						<span class="democar badge badge-dark" v-if="data.democar == true"
							>ASSIST's CAR</span
						>
						<span class="democar badge badge-dark" v-else>USER's CAR</span>
						<h2 class="px-2">{{ data.name }}</h2>
						<div v-html="data.specification" class="specification"></div>
					</div>
					<div class="col-12 col-md-6 mb-4">
						<img
							:src="data.main_image.url"
							class="img-fluid"
							:alt="data.name"
							v-if="data.main_image != undefined"
						/>
						<img src="/img/common/no_image.jpg" class="img-fluid" v-else />
					</div>
				</div>
			</div>
		</div>

		<div id="concept" class="block section-padding-50-0">
			<div class="container">
				<h3 class="block-header">
					Tuning Concept<br /><small>コンセプト</small>
				</h3>
				<div class="row mb-5">
					<div class="col-12">
						<div v-html="data.concept" class="concept"></div>
					</div>
				</div>
			</div>
		</div>

		<div
			id="achievement"
			class="block section-padding-50-0"
			v-if="data.achievements.length > 0"
		>
			<div class="container">
				<h3 class="block-header">INTRODUCTION<br /><small>紹介</small></h3>
				<div
					v-for="(item, index) in data.achievements"
					class="row mb-5"
					:key="index"
				>
					<div class="col-12 col-md-4">
						<img
							:src="item.achievement_image.url"
							class="img-fluid"
							:alt="'紹介メインイメージ' + index"
							v-if="item.achievement_image != undefined"
						/>
						<img
							src="/img/common/no_image.jpg"
							class="img-fluid"
							data-size="600x400"
							v-else
						/>
					</div>
					<div class="col-12 col-md-8">
						<div class="title">
							<h6>{{ item.subtitle }}</h6>
							<h4>{{ item.title }}</h4>
						</div>
						<div v-html="item.body" class="body p-2"></div>
					</div>
				</div>
			</div>
		</div>

		<div class="block section-padding-50-0">
			<div class="container">
				<div class="row">
					<div
						id="photo_gallery"
						class="col-12 col-sm-6"
						v-if="data.photo_galleries.length > 0"
					>
						<h3 class="block-header">
							Photo Gallery<br /><small>フォトギャラリー</small>
						</h3>

						<div class="row images">
							<div
								v-for="(item, index) in data.photo_galleries"
								:key="'photo' + index"
								class="col-12"
							>
								<img
									:src="item.picture.url"
									:class="{
										'img-fluid': true,
										'img-thumbnail': true,
										'mb-3': true,
										'd-none': index == 0 ? false : true,
									}"
									:alt="'画像' + index"
									data-size="600x400"
								/>
								<p v-if="index == 0 ? true : false" class="caution">
									※ 写真をクリックするとギャラリーが見れます
								</p>
							</div>
						</div>

						<!-- Root element of PhotoSwipe. Must have class pswp. -->
						<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
							<div class="pswp__bg"></div>
							<div class="pswp__scroll-wrap">
								<div class="pswp__container">
									<div class="pswp__item"></div>
									<div class="pswp__item"></div>
									<div class="pswp__item"></div>
								</div>
								<div class="pswp__ui pswp__ui--hidden">
									<div class="pswp__top-bar">
										<div class="pswp__counter"></div>
										<button
											class="pswp__button pswp__button--close"
											title="Close (Esc)"
										></button>
										<button
											class="pswp__button pswp__button--share"
											title="Share"
										></button>
										<button
											class="pswp__button pswp__button--fs"
											title="Toggle fullscreen"
										></button>
										<button
											class="pswp__button pswp__button--zoom"
											title="Zoom in/out"
										></button>
										<div class="pswp__preloader">
											<div class="pswp__preloader__icn">
												<div class="pswp__preloader__cut">
													<div class="pswp__preloader__donut"></div>
												</div>
											</div>
										</div>
									</div>
									<div
										class="
											pswp__share-modal
											pswp__share-modal--hidden
											pswp__single-tap
										"
									>
										<div class="pswp__share-tooltip"></div>
									</div>
									<button
										class="pswp__button pswp__button--arrow--left"
										title="Previous (arrow left)"
									></button>
									<button
										class="pswp__button pswp__button--arrow--right"
										title="Next (arrow right)"
									></button>
									<div class="pswp__caption">
										<div class="pswp__caption__center"></div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div
						id="movies"
						class="col-12 col-sm-6"
						v-if="data.movies.length > 0"
					>
						<div id="movies" class="headline">
							<h3 class="block-header">
								Movies<br /><small>YouTube走行動画</small>
							</h3>
						</div>
						<div class="row item">
							<div
								v-for="(item, index) in data.movies"
								class="col-sm-6 col-12 mb-3"
								:key="index"
							>
								<div class="embed-responsive embed-responsive-16by9">
									<iframe
										width="560"
										height="315"
										:src="item.youtube_url"
										frameborder="0"
										allow="autoplay; encrypted-media"
										allowfullscreen
									></iframe>
								</div>
								<h5>
									<small>{{ toYMD(item.published_at) }}</small
									><br /><a :href="item.youtube_url" target="_blank">{{
										item.title
									}}</a>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div id="spec" class="block section-padding-50-0">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<div class="headline">
							<h3 class="block-header">
								Tuning<br /><small>チューニング・スペック</small>
							</h3>
						</div>

						<div
							class="block-category"
							v-for="(tuning, index) in data.tunings"
							:key="'tuning' + index"
						>
							<h4 class="category mb-3">
								<i class="fas fa-chevron-circle-right mr-2"></i
								>{{ tuning.title }}
							</h4>
							<div v-html="tuning.explain" class="category_text"></div>

							<div class="row mb-4">
								<div v-html="tuning.parts" class="col-sm-6 col-12 list"></div>
								<div class="col-sm-6 col-12 pictures">
									<div class="row">
										<template
											v-for="(parts_image, index) in tuning.parts_images"
										>
											<div class="col-6 mb-4" :key="'parts_images' + index">
												<img
													:src="parts_image.tuning_parts_image.url"
													class="img-fluid"
													:alt="'parts_images' + index"
												/>
											</div>
										</template>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- ##### Contact Form Area End ##### -->
	</div>
</template>

<script>
import axios from "axios";

import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";

import PhotoSwipe from "photoswipe/dist/photoswipe.min.js";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default.min.js";

export default {
	title: "--",
	components: {
		//Sidebar,
	},
	data() {
		return {
			isLoading: false,
			data: null,
		};
	},
	mounted: function () {
		this.getData();
	},
	methods: {
		getData: function () {
			this.isLoading = true;
			// microcms
			let endpoint =
				"https://assist-concept-cars.microcms.io/api/v1/cars/" +
				this.$route.params.id;
			// プレビュー対応
			const query = this.$route.query;
			if (query.draftKey != undefined) {
				endpoint += "?draftKey=" + query.draftKey;
			}
			axios
				.get(endpoint, {
					headers: {
						"X-MICROCMS-API-KEY": "Zcnvkvp6KxnPWkf2WF9Uu7inXkkClTsv1YYr",
					},
				})
				.then((res) => {
					//console.log("res:" + JSON.stringify(res.data));
					this.data = res.data;
					document.title = this.data.name + " - ASSIST BMW tuning";
					// simulate AJAX
					setTimeout(() => {
						this.isLoading = false;
						this.setup();
					}, 500);
				});
		},
		setup: function () {
			// 画像リストを作成
			const items = Array.from(document.querySelectorAll("img[data-size]")).map(
				(i) => {
					const wh = i.getAttribute("data-size").split("x");
					return {
						src: i.getAttribute("src"),
						w: parseInt(wh[0], 10),
						h: parseInt(wh[1], 10),
					};
				}
			);
			// photoswipe.htmlで定義したルート
			const pswpElement = document.querySelector(".pswp");
			// 画像クリック時にギャラリーが表示されるようにする
			document.querySelectorAll("img[data-size]").forEach((e, i) => {
				e.onclick = () => {
					const gallery = new PhotoSwipe(
						pswpElement,
						PhotoSwipeUI_Default,
						items,
						{
							shareEl: false,
							index: i,
						}
					);
					gallery.init();
				};
			});
		},
		toYMD: function (str) {
			const d = new Date(Date.parse(str));
			const year = d.getFullYear();
			const month = d.getMonth() + 1;
			const day = d.getDate();
			return `${year}.${month}.${day}`;
		},
	},
};
</script>

<!-- 読み込んだHTMLテキスト向け -->
<style lang="scss">
#main-info {
	.specification p {
		margin: 0 0 1rem 0;
		padding: 0 0.5rem;
		color: #fff;
	}
}
#spec {
	.category_text {
		margin-bottom: 2rem;
		padding: 0 0.6rem;
	}
	.list {
		h3 {
			margin-bottom: 0.4rem;
			padding: 0.6rem;
			color: #152535;
			font-size: 1rem;
			border-left: none;
			border-bottom: 2px solid #1976d2;
		}
		p {
			margin: 0 0 2rem 0.6rem;
		}
	}
}
</style>
<style scoped lang="scss">
.democar {
	font-size: 1rem;
	background-color: #152535;
}
h3.block-header {
	font-size: 1.6rem;
	text-transform: uppercase;
	line-height: 1.4rem;
	small {
		text-transform: none;
		font-size: 0.8rem;
	}
}
#main-info {
	background-color: #1976d2;
	/*background-color: #152535;*/
	h2 {
		font-size: 3rem;
		color: #fff;
	}
	p {
		margin: 0 0 1rem 0;
		color: #fff;
	}
	table {
		th,
		td {
			color: #fff;
		}
	}
}
#concept {
	.concept {
		padding: 1rem;
		background-color: #eceff1;
	}
}
#achievement {
	.title {
		margin-bottom: 1rem;
		padding: 0.5rem 1rem;
		border-bottom: 2px solid #152535;
	}
}
#photo_grallery {
	p.caution {
		color: #152535;
		font-size: 0.8rem;
	}
}
#movies {
	small {
		font-size: 0.8rem;
	}
}
#spec {
	margin-bottom: 5rem;
	h4 {
		font-size: 1.2rem;
		padding: 1rem;
		background: #f4f4f4;
	}
	.block-category {
		padding: 1rem;
		border: 1px solid #152535;
		margin-bottom: 2rem;
		i {
			color: #1976d2;
		}
	}
	.category_text {
		margin-bottom: 2rem;
	}
	.list {
		h3 {
			padding: 0.6rem;
			color: #152535;
			font-size: 1rem;
		}
		p {
			margin-bottom: 1.2rem;
		}
	}
}
</style>
